 /* eslint-disable */
import {
    httpService
} from "../services/httpService";
import moment from "moment";
const _moment = moment;

let generic = {
    /**
     * 执行配置中需要eval的字段
     * @param config Array  配置
     * @param context Object  原数据
     */
    replaceEval(config, context = {}) {
        config = JSON.parse(JSON.stringify(config))
        if (config.length == 0) return config
        try {
            config.forEach((item) => {
                if (item.replaceEvalPath) {
                    // 精确定位需要eval的字段
                    item.replaceEvalPath.forEach((path) => {
                        let arr = path.split('/')
                        if (arr.length == 1) {
                            try {
                                item[arr[0]] = eval(item[arr[0]])
                            } catch (error) {
                                console.log(error);
                            }
                        } else {
                            arr.unshift('item')
                            // 目标key
                            let goalKey = arr.pop()
                            let tmp = arr.reduce((left, right) => {
                                try {

                                    return eval(left)[right]

                                } catch (error) {
                                    console.log(error);
                                }
                            })
                            try {


                                tmp[goalKey] = eval(tmp[goalKey])
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
        return config
    },
    /**
     * 执行配置中需要eval的字段
     * @param config Array  配置
     * @param context Object  原数据
     */
    replaceEvalSingle(config, context = {}) {
        config = JSON.parse(JSON.stringify(config))
        if (config.length == 0) return config
        config.forEach((item) => {
            if (item.replaceEvalPath) {
                // 精确定位需要eval的字段
                item.replaceEvalPath.forEach((path) => {
                    let arr = path.split('/')
                    if (arr.length == 1) {
                        try {
                     
                            item[arr[0]] = eval(item[arr[0]])
                       
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        arr.unshift('item')
                        // 目标key
                        let goalKey = arr.pop()
                        let tmp = arr.reduce((left, right) => {
                            try {
                     
                       
                                return eval(left)[right]
                            } catch (error) {
                                console.log(error);
                            }
                        })
                        try {
                     
                       
                            tmp[goalKey] = eval(tmp[goalKey])
                        } catch (error) {
                            console.log(error);
                        }
                    }
                })
            }
        })
        return config[0].params.metas[0]
    },
    /**
     * 执行配置中需要eval的字段
     * @param config Array  配置
     * @param context Object  原数据
     */
    replaceEvalObject(object, context = {}) {
        object = JSON.parse(JSON.stringify(object))
        if (object.replaceEvalPath && object.replaceEvalPath.length) {
            object.replaceEvalPath.forEach((path) => {
                let arr = path.split('/')
                if (arr.length == 1) {
                    try {
                        object[arr[0]] = eval(object[arr[0]])
                    } catch (error) {
                        console.log(error,object[arr[0]]);
                    }
                } else {
                    arr.unshift('object')
                    // 目标key
                    let goalKey = arr.pop()
                    let tmp = arr.reduce((left, right) => {
                        try {
                     
                            return eval(left)[right]
                       
                        } catch (error) {
                            console.log(error,left);
                        }
                    })
                    try {
                     
                        tmp[goalKey] = eval(tmp[goalKey])
                       
                    } catch (error) {
                        console.log(error,tmp[goalKey]);
                    }
                }
            })
        }
        return object
    },
    /**
     * 模版字符串替换
     * @param template  模版
     * @param context   数据源
     * @returns {*}
     */
    templateRender(template, context) {
        return template.replace(/\{\{(.*?)\}\}/g, (match, key) => context[key.trim()]);
    },
     /**
     * 执行配置中需要eval的字段
     * @param evalStr String  配置
     * @param context Object  原数据
     */
    replaceVal(evalStr,content) {
        if (evalStr.indexOf('context') == -1) return evalStr;
        let context = content || {};
        let val = ''
        try {
            val = eval(evalStr)
        } catch (error) {
            console.log(`${evalStr}_error`, error)
        }
        return val
    },
    /**
     * 获取通用配置
     * @param miceId
     * @param group
     * @param router
     * @returns {Promise<{}>}
     */
    async getConfigs({
        miceId,
        group,
        router
    }, context = {}) {
        let params = {
            db: "smartx_tpm",
            collection: "cfg_generic_modules",
            filter: {
                group,
                router,
            },
            sort: {
                Sort: 1,
            }
        };
        let action = 'QuerySettings'
        if(miceId) {
            action = 'GetFuncModule'
            params.ContextDataKeys = {
                miceId
            }
        }

        let res = await httpService[action](params);

        let config = {}
        if (res && res.success && res.content) {
            res.content[0]?.clientConfig.forEach((item) => {
                try {
                    if (item.expr && eval(item.expr)) {
                        config[item.code] = item
                      
                    }
                } catch (error) {
                    console.log(error,item.expr);
                }
            })
        }
        return config
    }
}
export default generic;
